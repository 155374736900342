import { AxiosInstance, AxiosResponse } from 'axios';
import escapeRegExp from '../utils/escapeRegExp';
import AbstractRequests from './AbstractRequests';

export default class AdvServicesRequest extends AbstractRequests<any> {
  private static BASE_URL = '/adv-services/';

  public constructor(instance: AxiosInstance) {
    super(instance, AdvServicesRequest.BASE_URL);
  }

  public getList(page = 1, limit = 10, filter = ''): Promise<AxiosResponse<any>> {
    const matchString = escapeRegExp(filter);
    const where = (typeof filter === 'string' && filter.length > 0)
      ? { name: { like: matchString, options: 'i' } }
      : {};
    return this.instance.get(AdvServicesRequest.BASE_URL, {
      params: {
        page, limit, where: JSON.stringify(where),
      },
    });
  }
}
