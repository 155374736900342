import React from 'react'
import {
    Autocomplete,
    TextField,
    Checkbox
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const AutocompleteComponent = ({ listOptions, selectedOptions, isRegion, handleChange, textFieldLabel, disabled }) => {

    return (
        <Autocomplete
            multiple
            style={{ width: "100%" }}
            id="grouped-timezone"
            options={listOptions}
            disableCloseOnSelect
            disabled={disabled}
            value={selectedOptions}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            groupBy={(option) => isRegion ? option.countryName : option.regionName}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {isRegion ?
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        : undefined}
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>

            )

            }
            onChange={handleChange}
            getOptionLabel={(option) => {
                return option.name
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={textFieldLabel}
                    variant="outlined"
                />
            )}
        />
    )
}

export default AutocompleteComponent