/* eslint-disable no-await-in-loop */
import { Card, Container, Step, StepLabel, Stepper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Requests from '../../BackofficeRequests/Requests';
import Page from '../../components/Page';
import { useAlerts } from '../../context/alert';
import CreateCreativeAddAssets from '../creative/CreateCreativeAddAssets';
import CreateCreativeInfoStep from '../creative/CreateCreativeInfoStep';
import CampaignChannelAndFilter from './CampaignChannelAndFilter';
import CampaignInfoStep from './CampaignInfoStep';
import CampaignScheduleStep from './CampaignScheduleStep';
import SelectAssetStep from './SelectAssetStep';


import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";

const stepsWizard = [
  {
    label: 'wizard.campaignInformation',
    component({ ...props }) { return <CampaignInfoStep {...props} />; },
  },
  {
    label: 'wizard.creativeAdding',
    component({ ...props }) {
      return <CreateCreativeInfoStep canSelectAdUnit {...props} />;
    }
  },
  {
    label: 'wizard.assetAdding',
    component({ ...props }) { return <CreateCreativeAddAssets campaignWizard {...props} />; }
  },
  {
    label: 'wizard.channelAndFilters',
    component({ ...props }) { return <CampaignChannelAndFilter {...props} />; }
  },
  {
    label: 'wizard.scheduling',
    component({ ...props }) { return <CampaignScheduleStep isWizard {...props} />; }
  },
];

const stepsNewCampaign = [
  {
    label: 'wizard.campaignInformation',
    component({ ...props }) { return <CampaignInfoStep {...props} />; },
  },
  {
    label: 'wizard.creativeSelection',
    component({ ...props }) { return <SelectAssetStep {...props} />; }
  },
  {
    label: 'wizard.channelAndFilters',
    component({ ...props }) { return <CampaignChannelAndFilter {...props} />; }
  },
  {
    label: 'wizard.scheduling',
    component({ ...props }) { return <CampaignScheduleStep {...props} />; }
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  header: {
    width: '100%',
    marginBottom: 15,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  }
}));

const Wizard = ({ wizard = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [filters, setFilters] = useState({})
  const [activeStep, setActiveStep] = useState(0);
  const [channel, setChannel] = useState('');
  const [channelList, setChannelList] = useState([]);
  const [campaign, setCampaign] = useState('');
  const [adUnitId, setAdUnitId] = useState('');
  const { showError, showMessage } = useAlerts();

  const { moment } = new MomentAdapter({ instance: momentTimezone });

  const steps = wizard ? stepsWizard : stepsNewCampaign;

  useEffect(() => {
    setActiveStep(0);
  }, [wizard])

  const handleSubmit = async (schedule, fixedSlots, dailySlots, periodicSlots) => {
    try {
      const { data } = await Requests.instance.campaign.createCampaign(
        campaign.title,
        campaign.publisher,
        campaign.cost,
        campaign.description,
        adUnitId,
        campaign.limit,
        campaign.dayLimit,
        campaign.nolimit,
        schedule
      );
      const { tags, regions } = campaign;
      /* await Requests.instance.campaign.addFilter(data.id, 'tag', tags); */
      /* await Requests.instance.campaign.addFilter(data.id, 'region', regions); */

      for (const name in filters) {
        //const d = await Requests.instance.campaign.updateFilter(id, filters[name], name);
        //console.log(d);
        //if (d.data.count === 0) {
        await Requests.instance.campaign.addFilter(data.id, name, filters[name]);
        //}
      }
      /* filters.map(async (values, name) => {
        return await Requests.instance.campaign.addFilter(data.id, name, values);
      }) */

      for (let i = 0; i < fixedSlots.length; ++i) {
        const slot = fixedSlots[i];
        const fixedTime = moment(slot.fixedDateTime, moment.ISO_8601).format("YYYY-MM-DDTHH:mm:ssZ");


        await Requests.instance.slot.createFixed(
          schedule,
          fixedTime,
          slot.duration,
          slot.priority,
          slot.preload,
          slot.preloadTime,
          data.id
        );
      }
      for (let i = 0; i < dailySlots.length; ++i) {
        const slot = dailySlots[i];
        await Requests.instance.slot.createDaily(
          schedule, slot, data.id
        );
      }
      for (let i = 0; i < periodicSlots.length; ++i) {
        const slot = periodicSlots[i];
        await Requests.instance.slot.createPeriodic(
          schedule, slot, data.id
        );
      }
      showMessage(t('Campagna creata correttamente!'));
      navigate('/app/campaigns');
    } catch (err) {
      showError(err.message);
    }
  };

  const updateFilters = (name, list) => {
    let _filters = filters;
    if (_filters[name] !== undefined) {
      _filters[name] = list;
    } else {
      _filters[name] = list;
    }
    setFilters(_filters);
  }

  return (
    <Page className={classes.root} title="Customers">
      <Container className={classes.container}>
        <Card className={classes.header}>
          <Stepper activeStep={activeStep}>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>

        {steps[activeStep].component({
          back: (step = 1) => setActiveStep(activeStep - step),
          next: (step = 1) => setActiveStep(activeStep + step),
          onCreateAdUnit: (id) => setAdUnitId(id),
          onCreateCampaign: (c) => setCampaign(c),
          idAdUnit: adUnitId,
          onSubmit: handleSubmit,
          setChannel,
          channel,
          setChannelList,
          channelList,
          updateFilters,
          filters,
          campaign,
          isWizard: wizard
        })}

      </Container>
    </Page>
  );
};

Wizard.propTypes = {
  wizard: PropTypes.bool
};

export default Wizard;
