/* eslint-disable max-len */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import firebase from 'firebase';
import 'firebase/auth';
import AssetRequests from './AssetRequests';
import CampaignRequests from './CampaignRequests';
import ChannelRequests from './ChannelRequests';
import CreativeRequests from './CreativeRequests';
import ScheduleRequests from './ScheduleRequests';
import SlotRequests from './SlotRequests';
import AdvServicesRequest from './AdvServiceRequests';

export default class Requests {
  private static REST_BASE_PATH = process.env.REACT_APP_REST_URL || 'http://localhost:3001/';

  private axiosInstance: AxiosInstance;

  private static _instance: Requests;

  public channel: ChannelRequests;

  public creative: CreativeRequests;

  public asset: AssetRequests;

  public schedule: ScheduleRequests;

  public slot: SlotRequests;

  public campaign: CampaignRequests;

  public adv: AdvServicesRequest;

  private constructor() {
    this.axiosInstance = axios.create({
      baseURL: Requests.REST_BASE_PATH,
    });
    this.axiosInstance.interceptors.request.use(async (config) => {
      const firebaseUser = firebase.auth().currentUser;
      if (!firebaseUser) {
        return config;
      }
      const token = await firebase.auth().currentUser?.getIdToken();
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    this.channel = new ChannelRequests(this.axiosInstance);
    this.creative = new CreativeRequests(this.axiosInstance);
    this.asset = new AssetRequests(this.axiosInstance);
    this.schedule = new ScheduleRequests(this.axiosInstance);
    this.slot = new SlotRequests(this.axiosInstance);
    this.campaign = new CampaignRequests(this.axiosInstance);
    this.adv = new AdvServicesRequest(this.axiosInstance);
  }

  public static get instance(): Requests {
    if (!Requests._instance) Requests._instance = new Requests();

    return Requests._instance;
  }

  public finalizeRegistration(name: string): Promise<AxiosResponse<any>> {
    return this.axiosInstance.post('/register', { name });
  }

  public analyticsEvents(startDate: Date, endDate: Date): Promise<AxiosResponse<any>> {
    return this.axiosInstance.get('analyticsEvents', {
      params: { startDate, endDate }
    });
  }

  /* public async generateEvents() {
    const r = (v: Array<any>) => {
      const i = Math.floor(Math.random() * v.length);
      return v[i];
    };
    const types = ['impression', 'interaction'];
    const vendors = ['hisense', 'lge', 'panasonic', 'philips',
      'samsung', 'sharp', 'sony', 'telefunken', 'toshiba', 'vestel'];
    const regions = ['abruzzo', 'basilicata', 'calabria', 'campania', 'emiliaromagna', 'friuliveneziagiulia', 'lazio', 'liguria', 'lombardia', 'marche', 'molise', 'piemonte', 'puglia', 'sardegna', 'sicilia', 'toscana', 'trentinoaltoadige', 'umbria', 'valledaosta', 'veneto'];
    const dates = ['2021-02-15T10:41:46.476Z', '2021-02-14T10:41:46.476Z', '2021-02-13T10:41:46.476Z', '2021-02-12T10:41:46.476Z',
      '2021-02-11T10:41:46.476Z', '2021-02-10T10:41:46.476Z', '2021-02-09T10:41:46.476Z'];
    const channels = ['6005602c907aa5fefa057078', '6005652a907aa5fefa05708b', '600a9754907aa5fefa057092', '60102208e97204c11e4ab954', '6013d755e97204c11e4ab975'];
    for (let i = 0; i < 1000; ++i) {
      const data = {
        type: r(types),
        vendor: r(vendors),
        region: r(regions),
        day: 0,
        month: 0,
        year: 0,
        hour: 0,
        minute: 0,
        date: r(dates),
        timezone: 'Europe/Rome',
        channelId: r(channels),
        campaignId: '600ecbbdd16d435d5d85d726',
        organizationId: '5f993e8731c4981c81b47797'
      };
      // eslint-disable-next-line no-await-in-loop
      await this.axiosInstance.post('analyticsEvent', data);
    }
  } */
}
