import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router';
import Requests from '../../BackofficeRequests/Requests';
import GenericStep from '../../components/GenericStep';
import { useAlerts } from '../../context/alert';
import AssetBuilder from '../asset/AssetBuilder';
import { ScreenWidgetDisplayType as DisplayType } from '../asset/AssetBuilder/ScreenWidget';

const useStyles = makeStyles(() => ({
  assetTitle: {
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: 'white',
  },
  assetImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: 168,
  },
}));

const CreateCreativeAddAssets = ({
  idAdUnit,
  campaignWizard = false,
  next,
  isActionCreative,
  setIsActionCreative
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showError, showMessage } = useAlerts();

  const [openBuilder, setOpenBuilder] = useState(false);
  const [assets, setAssets] = useState([]);

  const fetchAssets = async () => {
    if (idAdUnit !== '') {
      try {
        const res = await Requests.instance.creative.getAssetList(idAdUnit);
        await Promise.all(
          res.data.docs.filter((asset) => asset.type === DisplayType.ANIMATED)
            .map(async (asset) => {
              const animatedRequest = await axios.get(asset.publicUrl);

              asset.animatedJSON = animatedRequest.data;
            })
        );
        setAssets(res.data.docs);
      } catch (error) {
        showError(error.message);
      }
    }
  };

  const goNext = () => {
    if (assets.length > 0) next();
    else showError(t("Devi inserire almeno un'asset"));
  };

  const assetsContent = useMemo(() => assets.map((asset) => {
    let assetImage;

    if (asset.type === DisplayType.ANIMATED) {
      assetImage = (
        <Lottie
          className={classes.assetImage}
          height={168}
          options={{
            animationData: asset.animationJSON,
            loop: true,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet'
            }
          }}
          isPaused={false}
          isStopped={false}
        />
      );
    } else if (asset.type === DisplayType.INTERACTIVE_OVERLAY
      || asset.type === DisplayType.INTERACTIVE_VIDEO_SCALING) {
      assetImage = (
        <a href={asset.publicUrl} target="_blank" rel="noreferrer">
          <img className={classes.assetImage} alt="banner" src="/static/images/iframe-icon.svg" />
        </a>
      );
    } else {
      assetImage = (
        <img className={classes.assetImage} alt="banner" src={asset.publicUrl} />
      );
    }

    return (
      <Grid item md={3} sm={6} key={asset.id}>
        <Card style={{ margin: 2 }}>
          <CardContent>
            <Typography variant="h3" className={classes.assetTitle}>{asset.title}</Typography>
            <Typography variant="subtitle1">{asset.type}</Typography>
            <Box display="flex" justifyContent="center" height={200} padding={2}>
              {assetImage}
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                className={classes.deleteButton}
                startIcon={<DeleteForeverIcon />}
                variant="contained"
                onClick={async () => {
                  try {
                    await Requests.instance.asset.delete(asset.id);
                    fetchAssets();
                  } catch (error) {
                    showError(error.message);
                  }
                }}
              >
                {t('Elimina')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }), [assets]);

  return (
    <GenericStep
      onDone={campaignWizard ? undefined : () => { showMessage(t('Ad unit creata correttamente')); navigate('/app/creatives'); }}
      onNext={campaignWizard ? goNext : undefined}
    >
      <Box marginLeft={4} marginRight={4} display="flex" justifyContent="space-between" marginBottom={1}>
        <Typography variant="h2">{t('adUnit.assetTitle')}</Typography>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenBuilder(true)}
          size="small"
        //disabled={assets.length >= 1}
        >
          {t('Aggiungi')}
        </Button>
      </Box>
      <Divider />
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        {assetsContent}
      </Grid>

      <Dialog
        fullScreen
        open={openBuilder}
        onClose={() => setOpenBuilder(false)}
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              onClick={() => {
                setOpenBuilder(false);
                fetchAssets();
              }}
              size="large">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <AssetBuilder
          idAdUnit={idAdUnit}
          isActionCreative={isActionCreative}
          onUpload={() => {
            setOpenBuilder(false);
            fetchAssets();
          }}
        />
      </Dialog>
    </GenericStep>
  );
};

CreateCreativeAddAssets.propTypes = {
  idAdUnit: PropTypes.string.isRequired,
  campaignWizard: PropTypes.bool,
  next: PropTypes.func,
  isActionCreative: PropTypes.bool,
  setIsActionCreative: PropTypes.func,
};

export default CreateCreativeAddAssets;
