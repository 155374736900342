import { useCallback, useEffect, useState, useRef } from "react";
import GenericStep from "../../components/GenericStep"
import Requests from '../../BackofficeRequests/Requests';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../context/alert';
import momentTimezone from "moment-timezone";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { getRegionsForTimezone } from "../../utils/timezone";
import AutocompleteComponent from "../../components/Autocomplete/AutocompleteComponent";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert,
    Grid,
    Button,
    Box,
    Chip,
    Divider,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';

function filterProvincesByList(regions, providedProvinces) {
    return regions.map(region => {
        if (region.province) {
            const filteredProvinces = providedProvinces.filter(province => province.regionName === region.name)
            return {
                ...region,
                province: filteredProvinces
            };
        }
        return region
    });
}

const CampaignChannelAndFilter = ({ back, next, isWizard = false, channel, setChannel, updateFilters, filters, setChannelList, channelList }) => {

    const [channelSelected, selectChannel] = useState(channel);
    const [error, setError] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [listRegions, setListRegions] = useState([]);
    const [listProvinces, setListProvinces] = useState([]);
    const [selectedProvinces, setSelectedProvinces] = useState([]);
    const channelsListMap = useRef(null);

    const { showError, showMessage, showConfirm } = useAlerts();
    const { t, i18n } = useTranslation();
    const { moment } = new MomentAdapter({ instance: momentTimezone });
    const [countries, setCountries] = useState([]);
    const [vendors, setVendors] = useState(filters.vendors ?? []);
    const [currentVendor, setCurrentVendor] = useState("");

    const fetchSchedules = useCallback(async () => {
        try {
            const res = await Requests.instance.schedule.getList(1, 200);
            if (res.data.docs) {
                let schedules = [];
                channelsListMap.current = new Map();
                res.data.docs.forEach((channel) => {
                    if (channel.enabled) {
                        schedules.push(channel);
                        channelsListMap.current.set(channel.id, channel);
                    }
                })
                setChannelList(schedules);
            }
        } catch (err) {
            showError(err.message);
        }
    }, []);

    const extractProvinces = () => {
        const provinces = selectedRegions.reduce((acc, region) => {
            if (region.province) {
                acc.push(...region.province);
            }
            return acc;
        }, []);

        setListProvinces(provinces);
    }

    const getListOfCountries = () => {
        const list = [];

        if (countries.length > 0) {
            countries.forEach((country, index) => {
                if (country.regions.length > 0) {
                    country.regions.forEach((region, index) => {
                        let add = true;
                        if (selectedRegions.length > 0) {
                            selectedRegions.forEach(_region => {
                                if (region.name === _region.name) {
                                    add = false;
                                }
                            })
                        }
                        if (add) {
                            list.push({
                                ...region,
                                countryName: country.name,
                                id: country.id
                            })
                        }
                    })
                }
            })
        }

        return list;
    }

    const handleSubmit = () => {
        if (channelSelected !== "") {
            updateFilters('regions', filterProvincesByList(selectedRegions, selectedProvinces));
            localStorage.setItem('selectedProvinces', JSON.stringify(selectedProvinces));
            localStorage.setItem('selectedRegions', JSON.stringify(selectedRegions));
            next();
        } else {
            showError(t('channel.channelRequired'))
        }
    }

    function resetStorage() {
        localStorage.setItem('selectedProvinces', JSON.stringify([]));
        localStorage.setItem('selectedRegions', JSON.stringify([]));
    }

    const handleChangeChannel = async (e) => {
        if (channel !== "" && selectedRegions.length > 0) {
            const res = await showConfirm(t('Change Channel reset all filters'), t("Attenzione"));
            if (!res) return;
            updateFilters("regions", [])
            resetStorage();
            selectChannel(e.target.value)
            setChannel(e.target.value);
        } else {
            resetStorage();
            selectChannel(e.target.value)
            setChannel(e.target.value);
        }
    }

    function hasProvincesInRegions(regions) {
        return regions.some(region => region.province && Array.isArray(region.province) && region.province.length > 0);
    }

    const handleDeleteVendor = (e) => {
        const _vendors = vendors.filter(item => item !== e.target.parentNode.dataset.vendor)
        setVendors(_vendors);
        updateFilters('vendors', _vendors);
    }

    const handleProvinceChange = (event, newSelectedProvinces) => {
        setSelectedProvinces(newSelectedProvinces);
    };

    const handleRegionChange = (event, newSelectedRegions) => {
        setSelectedRegions(newSelectedRegions);
    };

    const showValidProvinceOptions = () => {
        const validSelectedOptions = selectedProvinces.filter(selected =>
            listProvinces.some(option =>
                option.name === selected.name &&
                selectedRegions.some(region => region.name === option.regionName)
            )
        );

        if (validSelectedOptions.length !== listProvinces.length) {
            setSelectedProvinces(validSelectedOptions);
        }
    }

    useEffect(async () => {
        await fetchSchedules();
        if (channel !== "") {
            const _countries = getRegionsForTimezone(channelsListMap.current.get(channel).timezone);
            setCountries(_countries);
        }
    }, [])

    useEffect(() => {
        if (channel !== "" && channelsListMap.current !== null) {
            const _countries = getRegionsForTimezone(channelsListMap.current.get(channel).timezone);
            setCountries(_countries);
            setListRegions([]);
            setSelectedRegions([])
        }
    }, [channel])

    useEffect(() => {
        extractProvinces();
        showValidProvinceOptions();
    }, [selectedRegions, listRegions]);

    useEffect(() => {
        if (countries.length > 0) {
            setListRegions(getListOfCountries());
            const regions = JSON.parse(localStorage.getItem('selectedRegions'))
            if (regions && regions.length > 0) {
                setSelectedRegions(regions)
            }
            const provinces = JSON.parse(localStorage.getItem('selectedProvinces'))
            if (provinces && provinces.length > 0) {
                setSelectedProvinces(provinces)
            }
        }
    }, [channel, countries]);

    return (
        <GenericStep
            onBack={() => { back(isWizard ? 2 : 1) }}
            onNext={handleSubmit}>
            <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.selectChannel')}</Typography>
            <Box marginLeft={5} marginRight={5} display="flex" justifyContent="space-between" marginBottom={1} alignItems="center">
                <Box width="50%">
                    <TextField
                        label={t('Canale')}
                        select
                        variant="outlined"
                        size='small'
                        style={{ width: "100%" }}
                        value={channelSelected}
                        onChange={handleChangeChannel}
                        error={error && channel === ''}
                        helperText={error && channel === '' ? t('campaign.scheduleRequired') : undefined}
                    >
                        {channelList.map((schedule) => (
                            <MenuItem key={schedule.id} value={schedule.id}>{schedule.title}</MenuItem>
                        ))}
                    </TextField>
                </Box>
                {channelSelected !== "" && channelsListMap.current !== null ?
                    <Box width="50%" display="flex" alignItems="center" justifyContent="space-around">
                        <Chip label={channelsListMap.current.get(channelSelected).timezone} />
                        <Chip label={moment().tz(channelsListMap.current.get(channelSelected).timezone).format("Z")} />
                    </Box>
                    : null}

            </Box>

            <Divider style={{ marginTop: 15 }} />

            <Box width="100%">
                <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.regions')}</Typography>
                {channel !== "" ?
                    <>
                        <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                            <AutocompleteComponent listOptions={listRegions} selectedOptions={selectedRegions} isRegion={true} handleChange={handleRegionChange} textFieldLabel={"Regions"} disabled={null} />
                        </Box>
                        {hasProvincesInRegions(selectedRegions) ?
                            <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around" marginTop={5}>
                                <AutocompleteComponent listOptions={listProvinces} selectedOptions={selectedProvinces} isRegion={false} handleChange={handleProvinceChange} textFieldLabel={"Provinces"} disabled={null} />
                            </Box>
                            : null}
                    </>
                    : <Box display="flex" alignItems="center" justifyContent="center" >
                        <Alert severity="warning">
                            {t('campaign.noChannelSelected')}
                        </Alert>
                    </Box>
                }
            </Box>

            <Divider style={{ marginTop: 15 }} />

            <Box width="100%">
                <Typography variant="h3" align="center" marginBottom="40px">{t('campaign.vendors.title')}</Typography>
                {channel !== "" ?
                    <Box width="100%" display="flex" alignItems="stretch" justifyContent="space-around">
                        <Grid container>
                            <Grid item xs={11}>
                                <TextField
                                    label={t('campaign.vendors.new')}
                                    noValidate
                                    autoComplete="off"
                                    fullWidth
                                    variant="outlined"
                                    value={currentVendor}
                                    onChange={(e) => {
                                        setCurrentVendor(e.target.value);
                                    }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Button
                                    variant="outlined"
                                    style={{ borderRadius: '100px' }}
                                    onClick={() => {
                                        if (currentVendor !== "") {
                                            const _vendors = vendors;
                                            let existsVendor = false;
                                            _vendors.forEach(e => {
                                                if (e === currentVendor.toUpperCase()) {
                                                    existsVendor = true;
                                                }
                                            })
                                            if (!existsVendor) {
                                                _vendors.push(currentVendor.toUpperCase());
                                                console.log("ADD TO VENDORS", _vendors);
                                                setVendors(_vendors);
                                                updateFilters('vendors', _vendors);
                                                setCurrentVendor("")
                                            } else {
                                                showError(t('campaing.vendors.exists'))
                                            }
                                        }
                                    }}
                                >+</Button>
                            </Grid>
                            <Grid xs={12} mt={2} style={{ minHeight: '100px', display: 'flex', justifyContent: 'flex-end', alignItems: 'cener', flexDirection: 'row-reverse' }} direction="row-reverse" spacing={1}>
                                {vendors.length > 0 ?
                                    vendors.map((e, i) => {
                                        return <Chip
                                            label={e}
                                            key={i}
                                            onDelete={handleDeleteVendor}
                                            deleteIcon={<DeleteIcon variant="plain" data-vendor={e} color="error" />}
                                            variant="outlined"
                                        />
                                    })
                                    : null}
                            </Grid>
                        </Grid>
                    </Box>
                    : <Box display="flex" alignItems="center" justifyContent="center" >
                        <Alert severity="warning">
                            {t('campaign.noChannelSelected')}
                        </Alert>
                    </Box>
                }
            </Box >

        </GenericStep >
    );

}

export default CampaignChannelAndFilter;